/***
 * TypeScript Entry Point
 * @author Arthur Moore <ArthurMO@mohela.com>
 * @copyright MOHELA 2024
 ***/

import 'vite/modulepreload-polyfill' // Required when using Backend Integration mode
import '@/main.js';
import './app.scss';

console.log("pathway")
